/* -------------------------------------------------------------
   Badminton Danmark Styles
  ------------------------------------------------------------ */
.sidebar {
    display: none;
}
.desktop .sidebar {
    display: block;
    float: left;
    width: 150px;
    height: 600px;
    background-color: #000000;
}

.homemenu {
    text-align: center;
    width: 100%;
}
.desktop .homemenu {
    float: left;
    text-align: center;
    width: 870px;
    height: 600px;
}
.homemenu .tmenu ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 300px;
}

.desktop .homemenu .tmenu ul {
    width: 800px;
}
.tablet .homemenu .tmenu ul {
    width: 680px;
}

.homemenu .tmenu ul li {
    display: block;
    float: left;
    overflow: hidden;
    text-align: center;
    padding-top: 0;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 110px;
}
.tablet .homemenu .tmenu ul li {
    width: 130px;
}
.desktop .homemenu .tmenu ul li {
    width: 160px;
}

.homemenu img {
    width: 100%;
    margin-bottom: 3px;
}

.homemenu .tmenu ul li a {
    font-family: Noto Serif TC;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    text-decoration: none;
}

.homemenu .tmenu ul li a:hover {
    background-color: #ffffff;
}
