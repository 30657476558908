.match-field{
    background-color: #035c03;
    border: solid 2px white;
    min-height: 8rem !important;
}

.match-box {
margin-left:auto;
margin-right: auto;
}


.btn-field {
    background-color: white;
}
.btnScore-field {
    background-color: white;

}



.grid__counter-component{
    padding-left: 40vh;
    padding-right: 40vh;
}
@media only screen and (max-width: 4320px) {
    .grid__counter-component{
        padding-left: 30vh;
        padding-right: 30vh;
    }
}

/* 
@media only screen and (max-width: 1090px) {
    .grid__counter-component{
        padding-left: 32rem;
        padding-right: 32rem;
    }
}

@media only screen and (max-width: 860px) {
    .grid__counter-component{
        padding-left: 27rem;
        padding-right: 27rem;
    }
}

@media only screen and (max-width: 685px) {
    .grid__counter-component{
        padding-left: 22rem;
        padding-right: 22rem;
    }
}

@media only screen and (max-width: 510px) {
    .grid__counter-component{
        padding-left: 17rem;
        padding-right: 17rem;
    }
}

@media only screen and (max-width: 322px) {
    .grid__counter-component{
        padding-left: 12rem;
        padding-right: 12rem;
    }
} */

.counter-component{
    text-align: -webkit-center !important;
}

.counter-component__count-label{
    font-size: 3rem;
}