.popupheader
{ 
	width:100%;
	height:30px; /* page-height = header-height + content-height + content-padding-top + content-padding-bottom */
	background-repeat:repeat;
	font-size:12px;
	font-weight:bold;
	color:#FFFFFF;
	margin:0;
	padding: 0;
	vertical-align:bottom;
}
.popupclose
{
	float: right;
	border: none;
	cursor: pointer;
}
.label
{
	font-weight: bold;
}
.error
{
	font-weight: bold;
	color: Red;
}
.hidden
{
	display: none;
}
.GridView th
{
	text-align: left;
}
.floatleft
{
	display:block;
	float:left;
}
.floatright
{
	display:block;
	float:right;
}
.helpText
{
}
.helpText ul li
{
	font-weight: normal;
}
.break
{
	clear:both;
}
/* .popup_disabledbackground
{
	position: absolute;
    left: expression(  ( ignoreMe2 = document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft  ) + 'px' );
    top : expression(  ( ignoreMe4  = document.documentElement.scrollTop  ? document.documentElement.scrollTop  : document.body.scrollTop  ) + 'px' );
    width :  expression( ( ignoreMe1 = document.documentElement.clientWidth  ? document.documentElement.clientWidth  : document.body.clientWidth )  + 'px' );
    height : expression( ( ignoreMe3 = document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight)  + 'px' );
	opacity: .4;
	filter: alpha(opacity=60);
	/* this hack is so it works in IE
	 * I find setting the color in the css gives me more flexibility 
	 * than the PNG solution.
	 */
	/* background-color:transparent !important;
	background-color: #000000; */
	/* this hack is for opera support
	 * you can uncomment the background-image if you don't care about opera.
	 * this gives you the flexibility to use any bg color that you want, instead of the png
	 */
	/* background-image: url("/SportsResults/Images/disabledbackground.png") !important; // For browsers Moz, Opera, etc.
	background-image:none;
	background-repeat: repeat;
	z-index: 1;
	display:none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}  */

.searchcriteria
{
	float:left;
	margin-right:5px;
	margin-bottom: 10px;
}
.numtextbox
{
    border: 1px solid #999999;
    background-color: #FFFFFF;
    width: 105px;     
}
.nametextbox
{
    border: 1px solid #999999;
    background-color: #FFFFFF;
    width: 175px;     
}
.datetextbox
{
    border: 1px solid #999999;
    background-color: #FFFFFF;
    width: 85px;     
}
.SRGridView
{
    border: none;
}
.SRGridView th
{
	text-align: left;
	font-weight: bold;
    border: none;
	border-bottom: 2px solid #000000;
	vertical-align: bottom;
	white-space:nowrap;
	padding-right: 6px;
	padding-left: 0px;
}
.SRGridView td
{
    border: none;
	padding-right: 3px;
	padding-left: 0px;
}
.SRGridView .alt
{
	background-color: #f3f3f3;
}

/*
	Stylesheet for Tigra Calendar v5.0
	Product is Public Domain (Free for any kind of applicaiton, customization and derivative works are allowed) 
	URL: http://www.softcomplex.com/products/tigra_calendar/

	- all image paths are relative to path of stylesheet
	- the styles below can be moved into the document or in existing stylesheet

*/

/* input box in default state */ 
.tcalInput {
	padding-right: 20px;
	cursor: pointer;
}

/* additional properties for input boxe in activated state, above still applies unless in conflict */
.tcalActive {
}
/* container of calendar's pop-up */
#tcal {
	position: absolute;
	visibility: hidden;
	z-index: 100;
	width: 250px;
	background-color: white;
	margin-top: 2px;
	padding: 0 2px 2px 2px;
	border: 1px solid silver;

	-moz-box-shadow: 3px 3px 4px silver;
	-webkit-box-shadow: 3px 3px 4px silver;
	box-shadow: 3px 3px 4px silver;
	-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='silver')";
	filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='silver');
}

/* table containing navigation and current month */
#tcalControls {
	border-collapse: collapse;
	border: 0;
	width: 100%;
}
#tcalControls td {
	border-collapse: collapse;
	border: 0;
	width: 16px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}
#tcalControls th {
	border-collapse: collapse;
	border: 0;
	padding: 0;
	line-height: 25px;
	font-size: 12px;
	text-align: center;
	font-family: Tahoma, Geneva, sans-serif;
	font-weight: bold;
	white-space: nowrap;
}
#tcalControls th select {
	line-height: 25px;
	font-size: 12px;
	text-align: center;
	font-family: Tahoma, Geneva, sans-serif;
	font-weight: bold;
	white-space: nowrap;
}
.tcalPrevYear { padding-left: 10px; padding-right: 10px; }
.tcalPrevMonth { padding-right: 10px; }
.tcalNextMonth { padding-left: 10px;}
.tcalNextYear { padding-left: 10px;}

/* table containing week days header and calendar grid */
#tcalGrid {
	border-collapse: collapse;
	border: 1px solid silver;
	width: 100%;
}
#tcalGrid th {
	border: 1px solid silver;
	border-collapse: collapse;
	padding: 3px 0;
	text-align: center;
	font-family: Tahoma, Geneva, sans-serif;
	font-size: 12px;
	background-color: gray;
	color: white;
}
#tcalGrid td {
	border: 0;
	border-collapse: collapse;
	padding: 3px 0;
	text-align: center;
	font-family: Tahoma, Geneva, sans-serif;
	width: 14%;
	font-size: 12px;
	cursor: pointer;
}		
#tcalGrid td.tcalOtherMonth { color: silver; }
#tcalGrid td.tcalWeekend { background-color: #f4f4f4 /*#ACD6F5*/; }
#tcalGrid td.tcalToday { border: 1px solid #000000 /*red*/; }
#tcalGrid td.tcalSelected { font-weight:bold; /*background-color: #FFB3BE;*/ }		

/*
    Responsive classes
*/
.mobile .hide-mobile {display: none; visibility:hidden;}
.desktop .hide-desktop {display: none; visibility:hidden;}
.tablet .hide-tablet {display: none; visibility:hidden;}
.desktop-tablet .hide-desktop-tablet {display: none; visibility:hidden;}
.mobile .responsive-warning { background-color: red; color:#ffffff; padding: 20px 20px 20px 20px; font-weight:bold; text-align:center; }
.desktop-tablet .responsive-warning { display:none; }
.break-mobile { display:none; }
.mobile .break-mobile { display:block; clear:both; }
.break-desktop { display:none; }
.desktop .break-desktop { display:block; clear:both; }
.break-desktop-tablet { display:none; }
.desktop-tablet .break-desktop-tablet { display:block; clear:both; }
.show-mobile-only {display:none; visibility:hidden;}
.mobile .show-mobile-only {display:inherit; visibility:visible;}
.show-tablet-only {visibility:hidden;}
.tablet .show-tablet-only {visibility:visible;}
.show-desktop-only {visibility:hidden;}
.desktop .show-desktop-only {visibility:visible;}

.mobile .responsive-table table, .mobile table.responsive-table {width: 100%;}
.mobile .responsive-table table td, .mobile table.responsive-table td {display: block;border: none;}
.mobile .responsive-table table th, .mobile table.responsive-table th {display: none;border: none;}

.mobile .mobile-w25 {box-sizing:border-box; width:25%; margin-left:0; margin-right:0; } 
.mobile .mobile-w25 .textbox, .mobile .mobile-w25 .selectbox,.mobile .textbox.mobile-w25, .mobile .selectbox.mobile-w25 {box-sizing:border-box; width:100%; margin-left:0; margin-right:0; height: 34px;} 
.mobile .mobile-w33 {box-sizing:border-box; width:33%; margin-left:0; margin-right:0; } 
.mobile .mobile-w33 .textbox, .mobile .mobile-w33 .selectbox,.mobile .textbox.mobile-w33, .mobile .selectbox.mobile-w33 {box-sizing:border-box; width:100%; margin-left:0; margin-right:0; height: 34px;} 
.mobile .mobile-w50 {box-sizing:border-box; width:50%; margin-left:0; margin-right:0; } 
.mobile .mobile-w50 .textbox, .mobile .mobile-w50 .selectbox,.mobile .textbox.mobile-w50, .mobile .selectbox.mobile-w50 {box-sizing:border-box; width:100%; margin-left:0; margin-right:0;  height: 34px;} 
.mobile .mobile-w75 {box-sizing:border-box; width:75%; margin-left:0; margin-right:0; } 
.mobile .mobile-w75 .textbox, .mobile .mobile-w75 .selectbox,.mobile .textbox.mobile-w75, .mobile .selectbox.mobile-w75 {box-sizing:border-box; width:100%; margin-left:0; margin-right:0; height: 34px;} 
.mobile .mobile-w100 {box-sizing:border-box; width:100%; margin-left:0; margin-right:0; } 
.mobile .mobile-w100 .textbox, .mobile .mobile-w100 .selectbox,.mobile .textbox.mobile-w100 , .mobile .selectbox.mobile-w100  {box-sizing:border-box; width:100%; margin-left:0; margin-right:0;  height: 34px;} 
