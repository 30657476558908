
.dialog-content{
    padding: 5%;
}

.dialogCustom >
.MuiDialog-container.MuiDialog-scrollPaper >
.MuiDialog-paper.MuiDialog-paperScrollPaper{
    overflow: initial;
}


.dialogTransparent >
.MuiDialog-container.MuiDialog-scrollPaper >
.MuiDialog-paper.MuiDialog-paperScrollPaper{
    background-color: transparent;
    box-shadow: none;
}

.root-container{
    padding-left: 1% !important;
    padding-right: 1% !important;
    padding-top: 8% !important;
    padding-bottom: 14.2% !important;
}

/* Mobile table */
@media screen and (max-width: 720px) {
  table > thead {
    display: none !important;
  }
  table > td {
    display: flex !important;
  }

  div.MuiPaper-root.MuiTableContainer-root{
    border:none !important;
  }
  .MuiPaper-elevation1{
    box-shadow: none !important;
  }
  
  tr { 
  display: block !important;
  float: left !important;
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  -webkit-box-shadow: 2px 6px 31px -23px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 6px 31px -23px rgba(66, 68, 90, 1);
  box-shadow: 2px 6px 31px -23px rgba(66, 68, 90, 1);
  }
  tr.MuiTableRow-root.table-row{
    width: 40%;
    margin: 3%;
  }
  tr.MuiTableRow-root.table-row:active{
    background-color: #f50057;
  }
  tr.MuiTableRow-root.table-row:hover{
    background-color: #f50057;
  }
  th, td { display: block !important; text-align: left !important; }
  td {
    border-bottom: none !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }
  table td::before {
    content: attr(aria-label) " " !important;
    font-weight: bold !important;
    width: 120px !important;
    min-width: 120px !important;
  }
}
/* Mobile table */
.leagueMatchSet__item:hover{
  background-color: #f50057;
}