/* -------------------------------------------------------------
   Badminton Danmark Styles
  ------------------------------------------------------------ */

/* -------------------------------------------------------------
   STANDARD CLASSES - this section defines standard html classes
   ------------------------------------------------------------ */
/* .MuiFormLabel-root.Mui-focused{
    color: #f50057 !important;
}

.MuiOutlined-root.Mui-focused.MuiInputBase-formControl > fieldset{
    border-color: #f50057 !important;
}

.MuiInputBase-input{
    color: #f50057;
} */
html {
    height: 100%;
}

body {
    background: #9e9e9e;
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    padding: 0;
    margin: 0 3px 0 3px;
    
}

a:link, a:visited, a.normalLink:link, a.normalLink:visited {
    color: #e01835;
    font-weight: bold;
    text-decoration: none;
}

a:hover, a.normalLink:hover {
    text-decoration: underline;
}

h1 {
    color: #000000;
    font: bold 22px Arial, Helvetica, sans-serif;
    margin: 0 0 0 0;
}

h2 {
    color: #000000;
    font: bold 15px Arial;
    margin: 0 0 7px 0;
}

h3 {
    color: #000000;
    font: bold 12px Arial;
    margin: 0 0 0 0;
}

h4 {
    color: #000000;
    font: bold 13px Arial;
    border: 0px solid #666;
    padding: 4px 2px 2px 2px;
    margin: 0px 0 10px 0;
    height: 19px;
}

    h4 img {
        margin: 0 5px 0 5px;
    }

h5 {
    color: #000000;
    font: bold 13px Arial;
    margin: 0 0 8px 0;
    text-transform: uppercase;
}

h6 {
    color: #000000;
    font: bold 15px Arial;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    border-bottom: 3px solid #000000;
}

    h6 img {
        margin: 0 5px 0 5px;
    }

hr {
    border-top: 1px solid #CCCCCC;
    border-bottom: 0;
}

img {
    border: none;
}

li {
    font-size: 100%;
}

p {
    font-size: 100%;
}

ol {
    list-style-type: decimal;
}

ul {
    color: #626262;
}

table, tr, td {
    border-collapse: collapse;
}
/* collapses any borders from any html tables */

/* --------------------- 
   END Standard Classes 
/* --------------------- */

/* -------------------- 
   LAYOUT Information
   -------------------- */
.mobile {
    margin: inherit;
    width: 100%;
    min-width: 300px;
}
.desktop {
    margin: 0 auto;
    width: 1170px;
    min-width: 320px;
}
.tablet {
    width: 100%;
}
.pageBody {
    clear: both;
    display: block;
}
.pageHeader {
    width: 100%;
    height: 65px;
    line-height: 65px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 48px;
    color: cyan;
    background-color: #f2002f;
    overflow: hidden;
}

.body2 { /* Main section */
    line-height: 1.5em;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    background-color: #ffffff;
    margin-top: 30px;
    width: 100%;
}
.mobile .body2 { /* Main section */
    margin-top: 15px;
}

.loginheader {
    color: #333333;
}
.loginpopup {
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #000000;
    width: auto;
}
/*.mobile .loginpopup {
    box-sizing: border-box;
    width: 100%;
    max-width: 320px;
}
.mobile .loginpopup input.textbox {
    box-sizing:border-box;
    height: 34px;
    width: 100%;
}*/
/* -------------------------------------------
	TOP MENU SECTION 
------------------------------------------- */
.topmenu1 {
    box-sizing:border-box;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 95%;
}
.mobile .topmenu1 {
    margin-top: 0px;
    margin-bottom: 0px;
}
.topmenu1 .tmenu {
    text-align: right;
    width: auto;
    white-space: nowrap;
}
.topmenu1 .tmenu ul li {
    padding-left: 20px;
}
.topmenu1 .tmenu ul li a {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: middle;
}
.topmenu1 .tmenu ul ul li a {
    height: auto;
    line-height: normal;
}
.mobile .topmenu1 .tmenu ul ul li a {
    height: auto;
    line-height: normal;
}
.mobile .topmenu1 .tmenu ul li a {
    height: 22px;
    line-height: 22px;
}
.mobile .topmenu1 .tmenu ul li a img {
    height: 22px;
}
.mobile .topmenu1 .tmenu ul li {
    padding-left: 10px;
}
.topmenu1 .tmenu ul ul li {
    padding-left: 0;
}
.topmenu1 .tmenu .admin ul li {
    width: 250px;
}
.topmenu1 .tmenu .admin ul {
    margin-left: -240px;
}
.topmenu1 .tmenu .admin ul ul {
    margin-left: 0;
}
.topmenu1 .tmenu .user ul {
    margin-left: -100px;
}
.topmenu1 .tmenu .user ul ul {
    margin-left: 0;
}

.topmenu1 .tmenu ul ul li {
    line-height: normal;
    vertical-align: middle;
}
.topmenu1 .tmenu ul a {
    font-family: Noto Serif TC;
    font-size: 14px;
}
.mobile .topmenu1 .tmenu ul a {
    font-size: 12px;
}
.topmenu1 .tmenu ul ul a {
    font-family: Arial, Verdana, Helvetica, sans-serif;
}
.topmenu2 {
    text-align: center;
    margin-bottom: 20px;
}
.topmenu2 ul {
    display: inline;
}
.topmenu2 ul li {
    display: inline;
    padding-left: 20px;
    padding-right:20px;
}
.tablet .topmenu2 ul li {
    padding-left: 3px;
    padding-right:3px;
}
.topmenu2 ul ul li {
    padding-left: 0;
    padding-right: 0;
}
.topmenu2 .tmenu ul a {
    font-family: Noto Serif TC;
    font-size: 16px;
    font-weight: bold;
}
.topmenu2 .tmenu ul ul a {
    font-size: 14px;
}
.mobile .topmenu2 {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-right: 0px;
    border-bottom: 1px solid #CCCCCC;
    border-top: 1px solid #CCCCCC;
}
.desktop-tablet .topmenu2 a.menu-burger {
    display:none;
}
.mobile .topmenu2 a.menu-burger {
    display: block;
    width: 24px;
    height: 14px;
    padding: 10px 10px 10px 10px;
    color: #000000;
    background-color: #eeeeee;
    border-color: #000000;
    border-radius: 3px;
    text-decoration: none;
    line-height: 5px;
    text-align: center;
}
.mobile .topmenu2 .tmenu {
    max-height: 0;
    overflow:hidden;
}
.mobile .topmenu2.show .tmenu {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
}
.mobile .topmenu2 .tmenu ul li {
    display: block;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 3px;
}

.mobile .topmenu2 .tmenu ul a {
    display: block;
}

.mobile .topmenu2 .tmenu ul ul {
    position: relative;
    border: none;
    border-radius: inherit;
}

.mobile .topmenu2 .tmenu ul ul li {
    background-color: #ffffff;
}

.mobile .topmenu2 .tmenu ul ul a {
    color: #000000;
}

.tmenu ul {
    list-style: none;
    margin: 0;
}

.tmenu ul li {
    display: inline-block;
}
.tmenu ul li.active a {
    font-weight: bold;
    color: #fff;
    background-color: #8b1627;
}

.tmenu ul li.show a {
    font-weight: bold;
}
.tmenu ul a {
    color: #000000;
    font-weight: normal;
    padding-top: 6px;
    padding-bottom: 5px;
    text-decoration: none;
}

.tmenu ul li a:hover {
    background-color: #eeeeee;
}

/*level 2*/
.tmenu ul ul li {
    padding-left: 0;
}
.tmenu ul ul {
    display: none;
    list-style: none;
    margin: 0;
    padding: 1px 0 0;
    position: absolute;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: #ffffff;
    text-align: left;
}

.tmenu ul li.show ul, .desktop-tablet .tmenu ul li:hover ul {
    display: block;
}

.tmenu ul ul li {
    display: block;
}
.tmenu ul li.show ul li a, .desktop-tablet .tmenu ul li:hover ul li a {
    font-weight: normal;
}

.tmenu ul li.show ul li.show a {
    font-weight: bold;
    background-color: #EEEEF0;
}

.tmenu ul li.show ul li a:hover {
    background-color: #eeeeee;
}

.tmenu ul li.active ul li.active a {
    font-weight: bold;
}

.tmenu ul ul li a {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
}


/*level 3*/
.tmenu ul ul ul {
    display: none;
    list-style: none;
    margin: 0;
    padding: 1px 0 0;
    position: relative;
    border: none;
}

.tmenu ul li.show ul li ul, .desktop-tablet .tmenu ul li:hover ul li ul {
    display: none;
}

.tmenu ul li.show ul li.show ul, .desktop-tablet .tmenu ul li:hover ul li.show ul {
    display: block;
}

.tmenu ul li.show ul li.show ul li ul, .desktop-tablet .tmenu ul li:hover ul li.show ul li ul {
    display: none;
}

.tmenu ul li.show ul li.show ul li.show ul, .desktop-tablet .tmenu ul li:hover ul li.show ul li.show ul {
    display: block;
}

.tmenu ul ul ul li {
    margin-bottom: 0px;
    border-bottom: none;
}

.tmenu ul li.show ul li.show ul li a, .desktop-tablet .tmenu ul li:hover ul li.show ul li a {
    font-weight: normal;
    background-color: #FFFFFF;
}

.tmenu ul li.show ul li.show ul li.show a, .desktop-tablet .tmenu ul li:hover ul li.show ul li.show a {
    font-weight: bold;
}

.tmenu ul li.show ul li.show ul li.show ul li a, .desktop-tablet .tmenu ul li:hover ul li.show ul li.show ul li a {
    font-weight: normal;
}

.tmenu ul li.show ul li.show ul li a:hover, .desktop-tablet .tmenu ul li:hover ul li.show ul li a:hover {
    background-color: #eeeeee;
}

.tmenu ul li.active ul li.active ul li.active a {
    font-weight: bold;
}

.tmenu ul ul ul li a {
    padding-left: 20px;
}

/*level 4*/
.tmenu ul ul ul ul {
    list-style: none;
    margin: 0;
    padding: 1px 0 0;
}

.tmenu ul ul ul ul li {
    margin-bottom: 0px;
}

.tmenu ul li.active ul li.active ul li.active ul li a {
    font-weight: normal;
}

.tmenu ul li.active ul li.active ul li.active ul li.active a {
    font-weight: bold;
}

.tmenu ul ul ul ul li a {
    padding-left: 30px;
}

/* ---------------------
   END Top Menu Section
--------------------- */

/* ---------------------- 
   User inputs - USE THIS CLASS FOR STANDARD USER INPUTS
	---------------------- */

.textbox {
    border: 1px solid #999999;
    margin-bottom: 5px;
    background-color: #FFFFFF;
    width: 250px;
}

.textboxSmall {
    border: 1px solid #999999;
    margin-bottom: 5px;
    background-color: #FFFFFF;
    width: 80px;
}

.textboxMultiLine {
    border: 1px solid #999999;
    margin-bottom: 5px;
    background-color: #FFFFFF;
    width: 350px;
    height: 100px;
}

.selectbox {
    border: 1px solid #999999;
    margin-bottom: 5px;
    background-color: #FFFFFF;
}



.responsive .selectbox, .responsive .textbox, .responsive .textboxSmall, .responsive .textboxMultiLine, .mobile .mobile-textboxstyle, .responsive .nametextbox {
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.responsive .selectbox:focus, .responsive .textbox:focus, .responsive .textboxSmall:focus, .responsive .textboxMultiLine:focus, .responsive .nametextbox:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}

.mobile .selectbox, .mobile .textbox, .mobile .textboxSmall, .mobile .textboxMultiLine, .mobile .mobile-textboxstyle, .mobile .nametextbox {
    box-sizing: content-box;
    height: 20px;
    padding: 6px 12px;
    line-height: 1.42857143;
}

.mobile span.checkbox input {
    box-sizing: border-box;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    vertical-align: middle;
}
.mobile .button, .mobile a.button {
    box-sizing: content-box;
    height: 20px;
    padding: 6px 12px;
    font-size: 14px;
}

.mobile .tcalInput {
    width: 125px;
}

.button, a.button {
    border: none;
    background-color: #e01835;
    border-radius: 25px;
    color: White;
    margin-bottom: 5px;
    padding: 3px 10px 3px 10px;
    min-width: 75px;
    cursor: pointer;
    text-decoration: none;
}

.leftLabel {
    width: 80px;
    display: block;
    float: left;
    font-weight: bold;
}

.rightLabel {
    width: 300px;
    display: block;
}

/* ---------------------- 
   Progress popup in master page
	---------------------- */
.progress_popup {
    padding: 0;
    margin: 0;
    float: left;
    width: auto;
    height: auto;
}

/* .progress_popup_div {
    background-color: #fdfdfd;
    border: 1px solid #999999;
    text-align: center;
    padding: 60px 0 0 0;
    height: 100px;
    z-index: 500;
    text-align: center;
    display: block;
    font-size: 250%;
    line-height: 30px;
    position: absolute;
    top: expression( (300 + ( ignoreMe3  = document.documentElement.scrollTop  ? document.documentElement.scrollTop  : document.body.scrollTop  ) ) + 'px' );
    left: expression( ( ( ignoreMe1 = document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft  ) + (( ignoreMe2 = document.documentElement.clientWidth  ? document.documentElement.clientWidth  : document.body.clientWidth )*20/100) )+ 'px' );
    width: expression( (( ignoreMe4 = document.documentElement.clientWidth  ? document.documentElement.clientWidth  : document.body.clientWidth )*60/100) + 'px' );
    margin: 0;
} */

.progress_popup > .progress_popup_div {
    position: fixed;
    top: 300px;
    left: 0;
    width: 60%;
    margin: 0 0 0 20%;
}
/* ---------------------- 
   Largetab's
	---------------------- */
.smallTab, .smallTabSelected {
    margin: 0 3px 0 0;
    border-width: 0px;
    width: 120px;
    height: 18px;
    float: left;
    text-align: center;
    text-transform: uppercase;
    font-size: 90%;
    line-height: 18px;
    font-family: Arial;
}


.LargeTab, .LargeTabSelected {
    margin: 0 3px 0 0;
    border-width: 0px;
    width: 135px;
    height: 18px;
    float: left;
    text-align: center;
    text-transform: uppercase;
    font-size: 90%;
    line-height: 18px;
    font-family: Arial;
}

.LargeTab, .smallTab {
    background-image: none;
    background-color: #E8E8E8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.LargeTabSelected, .smallTabSelected {
    background-color: #e01835;
    color: #FFFFFF;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.LargeTab a, .smallTab a {
    color: #4b4b4b;
    width: 100%;
    height: 100%;
    display: block;
}

.LargeTabSelected a, .smallTabSelected a {
    color: #FFFFFF;
    width: 100%;
    height: 100%;
    display: block;
}

.LargeTab a:hover, .smallTab a:hover {
    background-color: #e01835;
    color: #FFFFFF;
    text-decoration: none;
}

.LargeTabSelected a, .smallTabSelected a {
    font-weight: bold;
}

.LargeTabSelected a:hover, .smallTabSelected a:hover {
    text-decoration: none;
}

.LargeTabPage {
    border-width: 0px;
    padding: 5px 5px 5px 5px;
    height: auto;
    width: 558px;
    text-align: left;
    margin: 0 0 0 0;
}
/* ---------------------- 
   Disabled background
	---------------------- */
/* .DisabledBackground {
    position: absolute;
    left: expression( ( ignoreMe2 = document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft  ) + 'px' );
    top: expression( ( ignoreMe4  = document.documentElement.scrollTop  ? document.documentElement.scrollTop  : document.body.scrollTop  ) + 'px' );
    width: expression( ( ignoreMe1 = document.documentElement.clientWidth  ? document.documentElement.clientWidth  : document.body.clientWidth ) + 'px' );
    height: expression( ( ignoreMe3 = document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight) + 'px' );
    opacity: .4;
    filter: alpha(opacity=60);
    /* this hack is so it works in IE
	 * I find setting the color in the css gives me more flexibility 
	 * than the PNG solution.
	 */
    /* background-color: transparent !important;
    background-color: #000000; */
    /* this hack is for opera support
	 * you can uncomment the background-image if you don't care about opera.
	 * this gives you the flexibility to use any bg color that you want, instead of the png
	 */
    /* background-image: url("/Images/maskBG.png") !important; For browsers Moz, Opera, etc. */
    /* background-image: none;
    background-repeat: repeat;
    z-index: 1;
    display: none;
}  */

.popupparent > .DisabledBackground {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}


/* ---------------------- 
   GridViews (tables)
	---------------------- */
.GridView {
    border: 0px solid #FFFFFF;
    width: 97%;
}

.GridView th {
    border-style: none;
    border-width: 0;
    color: #FFFFFF;
    height: 20px;
    background-image: none;
    text-align: left;
    background-color: #e01835;
    font-weight: bold;
}

.GridViewTableRowAlt {
    border-style: none;
    border-width: 0;
    border-bottom: 1px solid #b0b0b0;
}

.GridView td, .GridViewTable td, .GridViewTableAlt td {
    border-style: none;
    border-width: 0;
}

.GridView tr /* even rows (starting with 0) */ {
}

.GridView .alt, .GridView .altread, .GridView .altunread, .GridViewTableAlt /* odd rows (starting with 0) */ {
    background-color: #f3f3f3;
}

.GridView .icon {
    width: 16px;
    height: 16px;
}

.GridView .pager {
    font-size: 16px;
    text-align: right;
}
/* specific for inbox:*/
.GridView .altread, .GridView .read {
    color: #404040;
}

.GridView .altunread, .GridView .unread {
    color: #000000;
    font-weight: bold;
}

.GridViewTable td, .GridViewTableAlt td {
    border-style: none;
    border-width: 0;
    border-bottom: 1px solid #b0b0b0;
    overflow: hidden;
}

.validationsummary {
    margin: 10px 0 10px 0;
    padding: 5px 5px 5px 5px;
    border-style: double;
    border-color: Red;
    background-color: #FFE1E1;
}

.validationsummary ul {
    margin: 0 0 0 0px;
    padding: 0;
}

.validationsummary ul li {
    color: Red;
    font-weight: bold;
    padding: 0;
    margin: 0 0 0 20px;
    background-color: Transparent;
}

.validation {
    color: Red;
}
