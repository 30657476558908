.edittable td 
{
	padding-right: 20px;
	padding-bottom: 10px;
}
.edittable .textbox, .edittable .selectbox
{
	width: 250px;
}
.edittable td span
{
	font-weight:bold;
}
.calendarpanel
{
	float: left;
	margin-right: 10px;
}
.calendartable
{
	border-collapse: collapse;
}
.calendartable .monthcell
{
	font-weight: bold;
	padding-right: 5px;
	border: none;
	width: 10px;
	vertical-align:middle;
}
.calendartable .checked
{
	background-color: Yellow;
}
.calendartable td
{
	border: solid 1px #cccccc;
	padding: 2px 2px 2px 2px;
	width: 56px;
	vertical-align: top;
}
.calendartable td span
{
	display: block;
	float: right;
	text-align:right;
	font-size: 8px;
	margin: 0 2px 0 0;
}
.calendartable td input
{
	display: block;
	float: left;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}
.calendartable td div
{
	clear:both;
    color: #626262; 
    font: bold 13px Arial; 
    margin: 3px 0 3px 0;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}
.teammatchdisciplines
{
	float:left;
	padding-left: 80px;
}
.teammatchlist
{
}
.teammatchlist table 
{
	border-collapse: collapse;
	margin-top: 20px;
}
.teammatchlist table tr
{
	border-top: solid 1px #cccccc;
	border-bottom: solid 1px #cccccc;
}
.teammatchlist table td
{
	padding-right: 20px;
}
.teammatchlist table .matchlisttop
{
	border-top: none;
}
.teammatchlist table .matchlisttop td
{
	font-weight: bold;
	text-align: left;
}
.teammatchlist table .matchlistcenter
{
	text-align: center;
}
.teammatchlist table .matchlistleft
{
	text-align: left;
}
.movematchicon
{
	height: 16px;
	width: 16px;
	cursor:pointer;
}
.teammatchtext
{
}
.teammatchtext .textbox
{
	width: 50px;
}
.standingsrulesleft
{
	float:left;
	padding-right: 40px;
	width: 300px;
}
.standingsrulesright
{
	float:left;
	width: 230px;
}
.standingsrulesleft table, .standingsrulesright table
{
	border-collapse: collapse;
}
.standingsrulesleft table tr, .standingsrulesright table tr
{
	border-top: solid 1px #cccccc;
	border-bottom: solid 1px #cccccc;
}
.standingsrulesleft table td, .standingsrulesright table td
{
	padding-right: 10px;
}
.radiobuttonlist, .radiobuttonlist label
{
	font-weight:normal;
}
.checkboxlist label
{
	padding-right: 30px;
	font-weight: bold;
}
.rankingpointsdisciplines
{
	float:left;
}
.rankingpoints
{
	width: 350px;
	float:left;
}
.rankingpoints table
{
	border-collapse:collapse;
}
.rankingpoints .textbox
{
	width: 40px;
}
.rankingpoints table td 
{
	padding-right: 10px;
}
.rankingpoints table .toprow
{
	border-top: solid 1px #cccccc;
}
.rankingpoints table th
{
	font-weight: bold;
	text-align:left;
}
.editdivision td 
{
    width: 300px;
	padding-right: 20px;
	padding-bottom: 5px;
}
.editdivision tr /*.regionsrow*/
{
	border-top: solid 1px #cccccc;
	border-bottom: solid 1px #cccccc;
}
.editdivision .regionsrow td label
{
	font-weight: normal;
}
.editdivision .textbox, .edittable .selectbox
{
	width: 250px;
}
.editdivision td span
{
	font-weight:bold;
}
.editdivision .value
{
	font-weight:normal;
}
.showdivision td 
{
	padding-right: 20px;
	padding-bottom: 0px;
}

.groupstable
{
	border-collapse: collapse;
}
.groupstable td
{
	border: solid 1px #cccccc;
	padding: 5px 10px 5px 5px;
	width: 150px;
	vertical-align:top;
}
.groupteamtable
{
	border-collapse: collapse;
	background-color: #ffffff;
}
.groupteamtable td span
{
	font-weight:bold;
}
.groupteamtable td
{
	text-align: center;
	vertical-align: bottom;
}
.groupteamtable .index
{
	vertical-align: middle;
	text-align: left;
	padding-right: 3px;
}
.groupteamtable .checkbox
{
	vertical-align: middle;
}
.groupteamtable .name
{
	width: 152px;
	text-align: left;
}
.groupteamtable .score
{
	width: 100px;
	white-space:nowrap;
}
.groupteamtable .number
{
	width: 50px;
}
.groupteamtable .teamnumber
{
	width: 30px;
}
.groupteamtable .teamtext .textbox
{
	width: 70px;
	margin: 0 0 0 0;
}
.groupteamtable .teamcourts
{
	white-space: nowrap;
}
.groupteamtable .teamcourts .textbox
{
	width: 15px;
	margin: 0 0 0 0;
}
.groupteamtable .teamtimeofday .textbox
{
	width: 35px;
	margin: 0 0 0 0;
}
.groupteamtable .teamvenue select
{
	width: 100px;
	margin: 0 0 0 0;
}
.groupteamtable .name .textbox
{
	width: 150px;
	margin: 0 0 0 0;
}
.groupteamtable .group 
{
	padding-right: 10px;
	text-align: left;
}
.groupteamtable .groupheader 
{
	text-align: left;
	padding-top: 5px;
}
.groupteamtable .groupfooter
{
	vertical-align: top;
	text-align: left;
	padding-bottom: 5px;
}
.groupteamtable .group .selectbox
{
	margin: 0 0 0 0;
}
.groupteamtable .number .textbox, .groupteamtable .score .textbox, .groupteamtable .teamnumber .textbox
{
	width: 30px;
	margin: 0 0 0 0;
}
.registrationteamtable td, .registrationteamtable th
{
    padding-right: 10px;
    text-align: left;
}
.menupopup
{
	background-color: #ffffff;
	border: solid 1px #000000;
	padding: 0 0 0 0;
	margin: 0 0 0 0; 
	display: none;
}
.menupopup td
{
	padding: 2px 10px 2px 10px;
}
.menupopup tr:hover
{
	background-color: #006634;
	color: #ffffff;
	cursor:pointer;
}
.matchplanleft
{
	float: left;
	padding-right: 20px;
}
.matchplanpage
{
}

.matchplan 
{
	width: 400px;
}
.matchplan td
{
	vertical-align:top;
}

.matchplan .eventroundsheader
{
	font-weight: bold;
}
.matchplan select
{
    max-width: 120px;
}
.matchplan .invalidround h3
{
	color: Red;
	font-style: italic;
}
.addmatchplanpopup, .addgrouppopup
{
	background-color: #ffffff;
	border: solid 1px #000000;
	padding: 5px 5px 5px 5px;
	margin: 0 0 0 0; 
	display: none;
}
.matchplanstatistics
{
	border-collapse: collapse;
}
.matchplanstatistics td
{
	border-left: solid 1px #cccccc;
	border-right: solid 1px #cccccc;
	padding: 2px 2px 2px 2px;
	vertical-align:top;
	text-align: center;
}
.matchplanstatistics .left
{
	text-align: left;
}
.matchplanstatistics .error
{
	background-color: #FFB7B7;
}
.matchplanstatistics tr
{
	border-bottom: solid 1px #cccccc;
}
.matchplanstatistics .headerrow
{
	border-bottom: 1px solid #000000;
	font-weight: bold;
}
.matchplanstatistics .footerrow td
{
	border-top: 1px solid #000000;
	border-bottom: 2px solid #000000;
}
.matchplanteams td
{
	padding-right: 5px;
}
.editvenue td 
{
	padding-right: 20px;
	padding-bottom: 5px;
}
.editvenue .textbox, .edittable .selectbox
{
	width: 250px;
}
.editvenue td span
{
	font-weight:bold;
}
.editvenue .value
{
	font-weight:normal;
}
.editvenuegrid .GridView
{
	width: auto;
}
.searchmatch .selectedplayerspan
{
	width: 150px;
}
.searchmatch .venue .textbox
{
	width: 150px;
}
.mobile .searchmatch .mobile-w100 .venue .textbox {
    width: 100%;
}
.searchmatch .date .textbox
{
	width: 100px;
}
.editmatchlinkbuttons a {
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 10px;
}
.checkteamlineresult
{
    border: 1px solid #aaaaaa;
    background-color: #fefe9a;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.editmatchpopup
{
	border: solid 1px #000000;
	padding: 5px 5px 5px 5px;
	background-color: #ffffff;
}
.editmatchpopup .date .textbox
{
	width: 100px;
}
.editmatchpopup .matchtimeconflict
{
	color: Red;
	font-weight: bold;
}
.editmatchpopup .contact 
{
	width: 300px;
	overflow: visible;
}
.editmatchpopup .contact .textbox
{
	width: 200px;
}
.result .textbox
{
	width: 30px;
	text-align: center;
}
.GridView .nowrap
{
	white-space:nowrap;
}
.leaguepopup
{
	border: 1px solid #000000;
	z-index: 2;
	padding:20px 20px 20px 20px;
	background-color: #FFFFFF;
	position: absolute;
    right: auto; bottom: auto;
	width: 400px;
	height: 200px;
	margin-left: -220px; /* width / 2 */
	margin-top: -120px; /* height / 2 */
	font-weight: bold;

}
.popupparent > .leaguepopup 
{
	left: 50%;
	top: 50%;
	position: fixed;
}
.walkoverhelppopup
{
	background-color: #FFFFCC;
	border: solid 1px #000000;
	padding: 5px 5px 5px 5px;
	width: 250px;
}
.matchresultpopup
{
	background-color: #ffffff;
	border: solid 1px #000000;
	padding: 0;
	width: 520px;
    height: 500px;
    max-height: 100%;
    overflow: hidden;
}
.matchresultpopupinner {
    padding: 5px 5px 5px 5px;
}
#matchresultpopupscroll {
    overflow: scroll;
}

.mobile .matchresultpopup {
    box-sizing: border-box;
    max-height: 100%;
    width: 100%;
    min-height: 0;
}
.mobile .matchresultpopup .tabcontainer {
    margin-top: -10px;
}
.mobile .matchresultpopup .tabcontainer {
    margin-top: -10px;
}
.mobile .matchresultpopup .tabcontainer div {
    margin-top: 10px;
}

.matchresultpopupinner .createpl .nopermission
{
	padding-top: 10px;
	font-weight: bold;
}
.matchresultpopupinner .playerlistheader
{
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: bold;
}
.printpage
{
}
.landscape .printpage
{
}
.printpage, .printpage .matchresultschema td
{
	font-family: Verdana, Georgia, "Times New Roman" , "Times serif"; /*defines the general font family for the entire site */
	font-size: 10pt;
}
.matchresultschema
{
	border-collapse:collapse;
	width: auto;
}
.matchresultschema td
{
	/*padding: 0px 5px 0px 5px;*/
	padding: 0px 2px 0px 2px;
	border: 1px solid #aaaaaa;
}

.matchresultschema .discipline, .matchresultschema .goldensetdiscipline
{
	width: auto;
	text-align: left;
}
.matchresultschema .goldensetdiscipline
{
	padding: 0 0 0 0;
}
.matchresultschema #goldensetrow.notselected td div,
.matchresultschema #goldensetrow.notselected td span,
.matchresultschema #goldensetrow.selected.playercount1 td .player2{
    display:none;
}
.mobile .matchresultschema .goldensetdiscipline {
    padding-top:5px;
}
.mobile .matchresultschema .goldensetdiscipline .selectbox {
    box-sizing: border-box;
    height: 34px;
    width: 100%;
}
.matchresultschema .player, .matchresultschema .playerwinner
{
	padding: 0 3px 0 3px;
	width: 160px;
}
.matchresultschema .teamhdr
{
    display:none;
}
.mobile .matchresultschema .teamhdr {
    display: block;
    font-weight:bold;
    font-size: smaller;
}
.mobile .matchresultschema {
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #000000;
}
.mobile .matchresultschema .toprow {
    display: none;
}
.mobile .matchresultschema td {
    box-sizing:border-box;
    display: block;
    border: none;
    width: 100%;
    padding-left: 0;
}
.mobile .matchresultschema td.result {
    text-align: left;
    padding-bottom: 3px;
    padding-top: 3px;
}
.matchresultschema td.resultall.editpopup {
    cursor: pointer;
}
.matchresultschema td.resultall {
    display:none;
}
.matchresultschema td.resultall .resultlbl{
    display:none;
}
.mobile .matchresultschema td.resultall {
    display: block;
    padding-bottom: 3px;
}
.mobile .matchresultschema.editmatch td.result {
    display: none;
}
.mobile .matchresultschema.editmatch td.wo {
    display: none;
}
.mobile .matchresultschema td.resultall .resultlbl {
    display: block;
    font-weight: bold;
    font-size: smaller;
}
.mobile .matchresultschema td.resultall.editpopup span {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    line-height: 1.42857143;
    font-size: 12px;
}
.mobile .matchresultschema td.result {
    display: inline-block;
    width: auto;
    text-align: left;
    padding-right: 10px;
}

.mobile .matchresultschema td.wo{
    display: inline-block;
	width: auto;
	text-align: left;
}
.mobile .matchresultschema tr {
    border-top: 1px solid #000000;
}
.mobile .matchresultschema .player, .mobile .matchresultschema .playerwinner {
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 3px;
    padding-top: 3px;
}
.mobile .matchresultschema .discipline {
    padding-top: 10px;
    font-weight: bold;
    font-size: larger;
}

.printpage .matchresultschema .printplayer div, .printpage .matchresultschema .printplayer5 div
{
	height: 9mm;
	padding-top: 3mm;
}
.printpage .matchresultschema .printplayer div
{
	width: 50mm;
}
.printpage .matchresultschema .printplayer5 div
{
	width: 40mm;
}
.matchresultschema .playerwinner
{
	background-color: #FFFFD9;
}
.matchresultschema .playerinvalid, .matchresultschema .playerinvalid a
{
	background-color: Red;
    color: #ffffff;
}
.matchresultschema .player span
{
	margin: 0 0 0 0;
	padding: 0 0 0 0;
	width: 190px;
	cursor: pointer;
	height: 20px;
	vertical-align: middle;
	font-size: 10px;
	font-family: Verdana, Arial;
	display: block;
	white-space: nowrap;
	overflow:hidden;
}
.matchresultschema .player .player2, .matchresultschema .printplayer .player2
{
	border-top: 1px dashed #aaaaaa;
}
.mobile .matchresultschema .player div {
    box-sizing: border-box;
    width: 100%;
}
.mobile .matchresultschema .player div span {
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    line-height: 1.42857143;
    font-size: 12px;
}
.mobile .matchresultschema .player .player2 {
    border-top: none;
}
.matchresultschema .substitute {
    width: 250px;
    vertical-align: top;
}
.mobile .matchresultschema .substitute {
    border-bottom: 1px solid #c0c0c0;
}
.mobile .matchresultschema .substitute .teamhdr {
    margin-top: 10px;
    font-size: 14px;
}
.matchresultschema .player a, .matchresultschema .playerwinner a
{
	margin-left: 2px;
	margin-right: 2px;
}
.matchresultschema .player a
{
	margin-left: 2px;
	margin-right: 2px;
/*	font-weight: normal;*/
}
.matchresultschema .toprow td
{
	font-weight: bold;
	text-align:center;
	border-top: 2px solid #000000;
	border-bottom: 2px solid #000000;
}
.matchresultschema .totalsrow td
{
	font-weight: bold;
	text-align:center;
	border-top: 2px solid #000000;
}
.matchresultschema .pointsrow
{
	font-weight: bold;
	text-align:center;
}
.matchresultschema .separatorrow td
{
	border: none;
}
.matchresultschema .totalscol
{
	font-weight: bold;
	text-align:right;
}
.matchresultschema .result .textbox
{
	text-align: center;
	margin: 0;
}
.matchresultschema .wo
{
	text-align: center;
	width: 0px;
}
.matchresultschema .result
{
	vertical-align:middle;
	text-align:right;
	white-space: nowrap;
}
.printpage
{
	margin: 0 0 0 0;
	padding: 0 0 0 0;
}
.printpage .matchresultschema .toprow, .printpage .matchresultschema .totalsrow, .printpage .matchresultschema .pointsrow
{
	height: 8mm;
}
.printpage .matchresultschema .result, .printpage .matchresultschema .totalscol
{
	width: 15mm;
	padding: 1mm;
	margin: 0;
	text-align: right;
}
.printpage .matchresultschema .wo
{
	width: 15mm;
	padding: 0;
	margin: 0;
}
.printpage .matchinfo
{
	margin-bottom: 4mm;
	border-collapse: collapse;
	width: 100%;
}
.printpage .matchinfo td 
{
	font-size: 11pt;
	border: 1px solid #cccccc;
	padding-left: 1mm;
	/*padding-right: 10mm;*/
	padding-bottom: 1mm;
}
.printpage .matchinfo td span
{
	font-size: 8pt;
	font-weight: normal;
}
.printpage .matchresult
{
	margin-bottom: 3mm;
}
.printpage .matchresult td
{
	font-size: 11pt;
	
}
.printpage .matchresult .resultbox
{
	height: 8mm;
	width: 8mm;
	padding: 0;
	margin: 0;
	border: 1px solid #aaaaaa;
	text-align: center;
	vertical-align: middle;
}
.printpage .matchresult .printwalkover
{
	font-weight: bold;
	text-decoration: underline;
}
.printpage .matchsignature
{
	display: block;
	float:left;
	width: 30%;
	padding:0;
	margin: 15mm 3% 0 0;
	border-top: 1px solid #aaaaaa;
	font-size: 8pt;
	overflow:hidden;
}
.pagebreak
{
	page-break-after: always;
	padding: 0 0 0 0;
	margin: 0 0 0 0;
	height: 0;
	line-height: 0;
}
.roundsgrid
{
	float:left;	
	width: 180px;
}
.roundsgrid .GridView .textbox
{
	width: 40px;
}
.roundsgrid .textbox
{
	width: 70px;
}
.matchpointsaddscore 
{
}
.matchpointsaddscore .textbox, .matchpointsaddaddlist .textbox
{
	width: 40px;
}
.matchpointsaddaddlist
{
	border-top: 1px solid #cccccc;
	padding-top: 20px;
	margin-top: 20px;
	border-bottom: 1px solid #cccccc;
	padding-bottom: 20px;
	margin-bottom: 20px;
}
.matchpointsaddaddlist table
{
	width:auto;
}
.matchpointsaddaddlist td
{
	width:auto;
}
.matchpointsgrid
{
	float: left;
	width: 400px;
}
.matchpointsgrid .GridView 
{
	width:auto;
}
.matchpointsgrid .GridView td
{
	width:auto;
	padding-right: 50px;
}
.matchpointsleft
{
	float:left;
}
.withdrawn
{
    text-decoration: line-through;
    color: #ff0000;
}
.matchplanschema h2
{
    font-size: 12px;
    padding-right: 30px;
}
.matchplanschema .textbox
{
    width: 30px;
}
.matchplanschema td
{
    vertical-align: middle;
    text-align: center;
}
.printindividualscoresheets .individualmatchinfo {
    margin-bottom: 8mm;
}
.printindividualscoresheets .individualmatchschema {
    width: 100%;
    border-collapse: collapse;
}
.printindividualscoresheets .individualmatchschema td {
    border: 1px solid #000000;
    padding-left: 5mm;
    vertical-align: middle;
}
.printindividualscoresheets .individualmatchschema .toprow {
}
.printindividualscoresheets .individualmatchschema .teamrow {
    min-height: 2cm;
}
.printindividualscoresheets .individualmatchschema  .title {
    font-size: large;
    font-weight: bold;
}
.printindividualscoresheets .individualmatchschema .set {
    width: 15mm;
    text-align: center;
}
.printindividualscoresheets .individualmatchschema  .teamname {
    font-weight: bold;
}
.printindividualscoresheets .individualmatchcourt, .printindividualscoresheets .individualmatchreferee {
    float:left;
    border-top: 1px solid #000000;
    margin-right: 10mm;
    font-size: small;
    margin-top: 13mm;
    margin-bottom: 5mm;
}
.printindividualscoresheets .individualmatchcourt {
    padding-right: 2cm;
}
.printindividualscoresheets .individualmatchreferee {
    padding-right: 8cm;
}
.printumpirescoresheets {
}
.printumpirescoresheets .individualmatchinfo {
    margin-bottom: 3mm;
}
.printumpirescoresheets .table1 {
    border-collapse: collapse;
    border: 2px solid #000000;
    width: 100%;
    margin: 0;
}
.printumpirescoresheets .table1 td {
    vertical-align: middle;
    padding-left: 2mm;
    padding-right: 2mm;
    border-bottom: 1px solid #000000;
    height: 7mm;
}
.printumpirescoresheets .table1  .title {
    font-size: large;
    font-weight: bold;
}
.printumpirescoresheets .table1 .teamname {
    font-weight: bold;
}
.printumpirescoresheets .table1 .set {
    border: 1px solid #000000;
    text-align: center;
    width: 10mm;
    padding: 0;
}
.printumpirescoresheets .table1 .r1 {
    width: 26mm;
    border-left: 2px solid #000000;
}
.printumpirescoresheets .table1 .r2 {
    width: 26mm;
}
.printumpirescoresheets .table2 {
    border-collapse: collapse;
    border: 2px solid #000000;
    width: 100%;
    margin-top:1mm;
}
.printumpirescoresheets .table2 td {
    font-size: 9px;
    vertical-align: middle;
    padding-left: 2mm;
    padding-right: 2mm;
    border-bottom: 1px solid #000000;
    white-space:nowrap;
}
.printumpirescoresheets .table2.single td {
    height: 8mm;
}
.printumpirescoresheets .table2.double td {
    height: 4mm;
}
.printumpirescoresheets .table2 .p {
    padding: 0;
    width: 5mm;
    border-left: 1px solid #000000;
}
.printumpirescoresheets .table2 .t td {
    border-top: 2px solid #000000;
}
.printumpirescoresheets .table3 {
    border-collapse: collapse;
    border: 2px solid #000000;
    width: 100%;
    margin-top:1mm;
}
.printumpirescoresheets .table3 td {
    vertical-align: bottom;
    padding-left: 2mm;
    padding-bottom: 2mm;
    height: 7mm;
    border: 1px solid #000000;
    white-space:nowrap;
    width: 50%;
}
#PanelIndividualPointsPopup, #PanelIndividualPointsPopupDropDown {
    display: none;
    width: auto;
    border: 1px solid #000000;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    font-size: 14px;
    background-color: #ffffff;
    position: absolute;
}
#PanelIndividualPointsPopup table {
    width: auto;
}
#PanelIndividualPointsPopup table td {
    padding-right: 20px;
}
#PanelIndividualPointsPopup .team {
    font-weight: bold;
}
#PanelIndividualPointsPopup .team0 {
    text-align: right;
}
#PanelIndividualPointsPopup .point div {
    display: inline-block;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-sizing: border-box;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    vertical-align: middle;
    text-align: center;
    width: 50px;
    cursor: pointer;
}
#PanelIndividualPointsPopup table {
    margin-bottom: 10px;
}
#PanelIndividualPointsPopup .button {
    box-sizing: border-box;
    width: auto;
    height : 34px;
    padding-left: 12px;
    padding-right: 12px;
}
#PanelIndividualPointsPopup .wo  {
    max-width: 300px;
    margin-bottom: 10px;
}
#PanelIndividualPointsPopup .wo span {
    display: block;
    font-weight: bold;
}
#PanelIndividualPointsPopup .wo .selectbox {
    display: block;
}
#PanelIndividualPointsPopup .wo .help {
    font-size: 10px;
    font-weight: normal;
}

#PanelIndividualPointsPopupDropDown div {
    box-sizing:border-box;
    height:20px;
    width: 30px;
    padding-left: 10px;
    cursor: pointer;
}
#PanelIndividualPointsPopupDropDown div:hover {
    background-color: #c0c0c0;
}
